<template>
  <VContainer
    pa-0
    id="line-settings-container"
  >
    <div
      class="configure-chart-section"
      style="background-color: rgb(235, 235, 235);"
    >
      <VRow
        no-gutters
        class="px-7"
      >
        <VCol>
          <VSubheader class="pa-0">
            <strong>X-axis</strong>
          </VSubheader>
        </VCol>
      </VRow>
      <VRow class="px-7">
        <VCol
          md="6"
          class="pl-0 pt-0"
        >
          <VCard
            height="40px"
            rounded
          >
            <VCardActions
              style="height: 40px;"
            >
              <VSubheader class="pa-2">
                Show axis label
              </VSubheader>
              <VSpacer />
              <VSwitch v-model="settings.showXaxisLabel" />
            </VCardActions>
          </VCard>
        </VCol>
        <VCol
          md="6"
          class="pr-0 pt-0"
        >
          <VCard
            height="40px"
            rounded
          >
            <VCardActions
              style="height: 40px;"
            >
              <VSubheader class="pa-2">
                Show Gridlines
              </VSubheader>
              <VSpacer />
              <VSwitch v-model="settings.showXgridLines" />
            </VCardActions>
          </VCard>
        </VCol>
      </VRow>
    </div>
    <div class="configure-chart-section">
      <VRow
        no-gutters
        class="px-7"
      >
        <VCol>
          <VSubheader class="pa-0">
            <strong>Y-axis</strong>
          </VSubheader>
        </VCol>
      </VRow>
      <VRow class="px-7">
        <VCol
          md="6"
          pl-4
          class="pl-0 pt-0"
        >
          <VCard
            height="40px"
            rounded
          >
            <VCardActions
              style="height: 40px;"
            >
              <VSubheader class="pa-2">
                Show axis label
              </VSubheader>
              <VSpacer />
              <VSwitch v-model="settings.showYaxisLabel" />
            </VCardActions>
          </VCard>
        </VCol>
        <VCol
          md="6"
          pl-4
          class="pr-0 pt-0"
        >
          <VCard
            height="40px"
            rounded
          >
            <VCardActions
              style="height: 40px;"
            >
              <VSubheader class="pa-2">
                Show Gridlines
              </VSubheader>
              <VSpacer />
              <VSwitch v-model="settings.showYgridLines" />
            </VCardActions>
          </VCard>
        </VCol>
      </VRow>
    </div>
    <div style="background-color: rgb(235, 235, 235);">
      <VRow class="px-7">
        <VCol
          md="12"
          pl-4
          class="pl-0"
        >
          <VCard
            height="40px"
            rounded
            color="transparent"
          >
            <VCardActions
              style="height: 40px;"
            >
              <Swatches
                v-model="settings.lineColor"
                :value="settings.lineColor"
                :swatch-size="24"
                :spacing-size="12"
                :swatches="['#649bff', '#e49728', '#f5e02d', '#7479db', '#313131', '#64972f', '#b72f2f']"
                inline
              />
            </VCardActions>
          </VCard>
        </VCol>
      </VRow>
    </div>
  </Vcontainer>
</template>
<script>
import Swatches from "vue-swatches";
import Chart from '@/mixins/Chart';

export default {
  name: 'LineChartSettings',
  mixins: [Chart],
  components: {
    Swatches
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    defaultSettings: {
      type: Object,
      default: function () {
        return {
          cols: 4,
          showXaxisLabel: true,
          showYaxisLabel: true,
          showXgridLines: true,
          showYgridLines: true,
        };
      }
    }
  },
  data() {
    return {
      settings: {
        showXaxisLabel: true,
        showYaxisLabel: true,
        showXgridLines: true,
        showYgridLines: true,
      },
    }
  },
  mounted() {
    console.log('Default settings', this.defaultSettings);
    this.settings = this.defaultSettings;
  },
  methods: {
    chartSetting() {
      console.log('chart settings')
      this.$emit('setSettings', JSON.parse(JSON.stringify(this.settings)));
    }
  },
  watch: {
    settings: {
      handler: function (newVal, oldVal) {
        console.log('New VAL', newVal);
        this.chartSetting();
      },
      deep: true
    }
  }
}
</script>
<style lang="scss">
.vue-swatches__wrapper, .vue-swatches__container {
  background-color: transparent !important;
}

.vue-swatches__wrapper {
  display: flex;
  align-items: center;
}
#line-settings-container {
  .vue-swatches__trigger {
    height: 30px !important;
    width: 30px !important;
    box-shadow: 1px 1px 6px #aaa;
  }
  .v-text-field > .v-input__control > .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 2px !important;
  }
  .v-text-field.v-text-field--solo .v-label {
    padding: 0 !important;
  }
}
</style>