<template>
  <highcharts
    :options="chartOptions"
    ref="chart"
  />
</template>

<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { mapGetters } from 'vuex'
import BaseChart from "@/mixins/Chart";


HighchartsMore(Highcharts);

export default {
  name: "ListBarComponent",
  mixins: [BaseChart],
  components: {
    highcharts: Chart
  },
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "150px",
    },
    config: {
      type: Object,
      default: function () {
        return {
          isFill: false,
          fillColor: "#ffffff",
          lineColor: "#0092f9",
          showXaxisLabel: true,
          showXgridLines: true,
          showYaxisLabel: true,
          showYgridLines: true,
          showYscaleLabel: false,
          showLegend: false,
        };
      },
    },
  },
  data() {
    return {
      chartRef: null,
      dataCollection: {},
      chartOptions: {
        credits: {
          enabled: false
        },
        colors: this.chartColors,
        chart: {
          type: 'bar',
          height: this.height
        },
        title: {
          text: ''
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: this.getChartLabels,
          crosshair: true,
          labels: {
            enabled: this.enableXAxisLabel
          },
          gridLineWidth: this.enabledXGridLine
        },
        yAxis: {
          title: {
            text: this.chartTitle
          },
          labels: {
            enabled: this.enableYAxisLabel
          },
          gridLineWidth: this.enabledYGridLine
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: this.buildSeriesData,
      },
      chartStyles: {
        width: this.width,
        height: this.height,
      },
    };
  },
  computed: {
    ...mapGetters({
      dashboardFilters: 'reporting/filters'
    }),
    chartColor()  {
      return this.config.lineColor ? this.config.lineColor : undefined;
    },
    enableXAxisLabel () {
      return this.config.showXaxisLabel
    },
    enableYAxisLabel () {
      return this.config.showYaxisLabel
    },
    enabledXGridLine() {
      return this.config.showXgridLines ? 1 : 0;
    },
    enabledYGridLine() {
      return this.config.showYgridLines ? 1 : 0;
    },
    getChartLabels() {
      let currentFilter = this.dashboardFilters.start;
      let shouldSelect = this.reportData.find((reportData) => reportData.interval_name === currentFilter);
      if (!shouldSelect) {
        shouldSelect = this.reportData[0];
      }
      console.log('FFF', shouldSelect);
      return shouldSelect.report_data.labels;
    },
  },
  methods: {
    getDefaultConfig() {
      return this.config;
    },
    updateChart () {
      this.chartOptions = {
        credits: {
          enabled: false
        },
        colors: this.chartColors,
        chart: {
          type: 'bar',
          height: this.height
        },
        title: {
          text: ''
        },
        legend: {
          enabled: this.getCData.values.length > 1
        },
        xAxis: {
          categories: this.getChartLabels,
          crosshair: true,
          labels: {
            enabled: this.enableXAxisLabel
          },
          gridLineWidth: this.enabledXGridLine
        },
        yAxis: {
          title: {
            text: this.chartTitle
          },
          labels: {
            enabled: this.enableYAxisLabel
          },
          gridLineWidth: this.enabledYGridLine
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: this.buildSeriesData,
      };
      this.chartRef.update(this.chartOptions);
    }
  },
  mounted() {
    this.chartRef = this.$refs.chart.chart;
    this.updateChart();
  },
  watch: {
    config: {
      handler: function (newVal, oldVal) {
        this.updateChart();
      },
      deep: true
    }
  }
};
</script>